import {
  BooleanFilter,
  CommonFilter,
  isSelectFilter,
  NumberFilter,
  Option,
} from "typings/filter";

const TYPES = {
  TEXT: "input",
  RICH_TEXT: "text",
  NUMBER: "number",
  EMAIL: "email",
  IMAGE: "image",
  SELECT: "select",
  AUTOMATION: "automat",
  REFERENCE: "ref",
};

export function handleIsFilter(filter: CommonFilter) {
  switch (filter.type) {
    case TYPES.TEXT:
    case TYPES.EMAIL:
    case TYPES.AUTOMATION:
      return { [filter.nameDB]: { $eq: filter.value } };
    case TYPES.RICH_TEXT:
      return { [`${filter.nameDB}.text`]: { $eq: filter.value } };
    default:
      return {};
  }
}

export function handleIsNotFilter(filter: CommonFilter) {
  switch (filter.type) {
    case TYPES.TEXT:
    case TYPES.EMAIL:
    case TYPES.AUTOMATION:
      return { [filter.nameDB]: { $ne: filter.value } };
    case TYPES.RICH_TEXT:
      return { [`${filter.nameDB}.text`]: { $ne: filter.value } };
    default:
      return {};
  }
}

export function handleContainFilter(filter: CommonFilter) {
  switch (filter.type) {
    case TYPES.TEXT:
    case TYPES.EMAIL:
    case TYPES.AUTOMATION:
      return { [filter.nameDB]: { $regex: filter.value } };
    case TYPES.RICH_TEXT:
      return { [`${filter.nameDB}.text`]: { $regex: filter.value } };
    case TYPES.SELECT:
      if (isSelectFilter(filter) && !filter.isMulti) {
        return {
          [filter.nameDB]: { $eq: (filter.value as unknown as Option).value },
        };
      }

      if (isSelectFilter(filter) && filter.isMulti) {
        return {
          [filter.nameDB]: {
            $in: filter.value?.reduce((acc: string[], item) => {
              if (item.value) {
                acc.push(item.value);
              }

              return acc;
            }, []),
          },
        };
      }

    case TYPES.REFERENCE:
      if (isSelectFilter(filter) && !filter.isMulti) {
        return {
          [filter.nameDB]: { $eq: (filter.value as unknown as Option).value },
        };
      } else {
        const filterValue = Array.isArray(filter.value)
          ? filter.value
          : [filter.value]; // TODO: Need this because of the type of logic of storing single and multiple Refs.
        // Currently server stores single Ref as object and multiple Refs as array of objects.

        return {
          [filter.nameDB]: {
            $in: filterValue.reduce((acc: string[], item: Option) => {
              if (item.value) {
                acc.push(item.value);
              }

              return acc;
            }, []),
          },
        };
      }
    default:
      return {};
  }
}

export function handleNotContainFilter(filter: CommonFilter) {
  switch (filter.type) {
    case TYPES.TEXT:
    case TYPES.EMAIL:
    case TYPES.AUTOMATION:
      return { [filter.nameDB]: { $not: { $regex: filter.value } } };
    case TYPES.RICH_TEXT:
      return { [`${filter.nameDB}.text`]: { $not: { $regex: filter.value } } };
    case TYPES.SELECT:
      if (isSelectFilter(filter) && !filter.isMulti) {
        return {
          [filter.nameDB]: { $ne: (filter.value as unknown as Option).value },
        };
      }
      if (isSelectFilter(filter) && filter.isMulti) {
        return {
          [filter.nameDB]: {
            $nin: filter.value?.reduce((acc: string[], item) => {
              if (item.value) {
                acc.push(item.value);
              }

              return acc;
            }, []),
          },
        };
      }

    case TYPES.REFERENCE:
      if (isSelectFilter(filter) && !filter.isMulti) {
        return {
          [filter.nameDB]: { $ne: (filter.value as unknown as Option).value },
        };
      } else {
        const filterValue = Array.isArray(filter.value)
          ? filter.value
          : [filter.value]; // TODO: Need this because of the type of logic of storing single and multiple Refs.

        return {
          [filter.nameDB]: {
            $nin: filterValue?.reduce((acc: string[], item: Option) => {
              if (item.value) {
                acc.push(item.value);
              }

              return acc;
            }, []),
          },
        };
      }
    default:
      return {};
  }
}

export function handleIsEmptyFilter(filter: CommonFilter) {
  switch (filter.type) {
    case TYPES.TEXT:
    case TYPES.EMAIL:
    case TYPES.AUTOMATION:
      return {
        $or: [
          { [filter.nameDB]: { $exists: false } },
          { [filter.nameDB]: { $eq: null } },
          { [filter.nameDB]: { $eq: "" } },
        ],
      };
    case TYPES.RICH_TEXT:
      return {
        $or: [
          { [filter.nameDB]: { $exists: false } },
          { [filter.nameDB]: { $eq: null } },
          { [`${filter.nameDB}.text`]: { $eq: "" } },
        ],
      };
    case TYPES.IMAGE:
    case TYPES.NUMBER:
    case TYPES.SELECT:
      if ((filter as any).isMulti) {
        return {
          $or: [
            {
              $and: [
                { [filter.nameDB]: { $type: "array" } },
                { [filter.nameDB]: { $size: 0 } },
              ],
            },
            {
              [filter.nameDB]: { $exists: false },
            },
            {
              [filter.nameDB]: { $eq: null },
            },
          ],
        };
      } else {
        return {
          $or: [
            {
              [filter.nameDB]: { $exists: false },
            },
            {
              [filter.nameDB]: { $eq: null },
            },
          ],
        };
      }
    case TYPES.REFERENCE:
      return {
        $or: [
          {
            [filter.nameDB]: {
              $exists: false,
            },
          },
          {
            [filter.nameDB]: null,
          },
          {
            [filter.nameDB]: {
              $size: 0,
            },
          },
        ],
      };
    default:
      return {};
  }
}

export function handleIsNotEmptyFilter(filter: CommonFilter) {
  switch (filter.type) {
    case TYPES.TEXT:
    case TYPES.EMAIL:
    case TYPES.AUTOMATION:
      return {
        $and: [
          { [filter.nameDB]: { $exists: true } },
          { [filter.nameDB]: { $ne: null } },
          { [filter.nameDB]: { $ne: "" } },
        ],
      };
    case TYPES.RICH_TEXT:
      return {
        $and: [
          { [filter.nameDB]: { $exists: true } },
          { [filter.nameDB]: { $ne: null } },
          { [`${filter.nameDB}.text`]: { $ne: "" } },
        ],
      };
    case TYPES.IMAGE:
    case TYPES.NUMBER:
    case TYPES.SELECT:
    case TYPES.REFERENCE:
      if ((filter as any).isMulti) {
        return {
          [filter.nameDB]: {
            $not: { $size: 0 },
            $exists: true,
            $ne: null,
          },
        };
      } else {
        return {
          [filter.nameDB]: {
            $exists: true,
            $ne: null,
            $not: { $size: 0 },
          },
        };
      }
    default:
      return {};
  }
}

export function handleBooleanFilter(filter: BooleanFilter) {
  const parsedValue = filter.value === "true" ? true : false;

  if (filter.value === "false") {
    return {
      $or: [
        { [filter.nameDB]: { $exists: false } },
        { [filter.nameDB]: { $eq: null } },
        { [filter.nameDB]: { $eq: false } },
      ],
    };
  }

  return { [filter.nameDB]: { $eq: parsedValue } };
}

export function handleNumberFilter(filter: NumberFilter) {
  const optionsMap = {
    "=": "equal",
    "≠": "not-equal",
    "<": "less-than",
    ">": "greater-than",
    "≤": "less-than-equal",
    "≥": "greater-than-equal",
  };

  const option = optionsMap[filter.filterOption as keyof typeof optionsMap];

  const filterValue = parseInt(filter.value, 10);

  switch (option) {
    case "equal":
      return { [filter.nameDB]: { $eq: filterValue } };
    case "not-equal":
      return { [filter.nameDB]: { $ne: filterValue } };
    case "less-than":
      return { [filter.nameDB]: { $lt: filterValue } };
    case "greater-than":
      return { [filter.nameDB]: { $gt: filterValue } };
    case "less-than-equal":
      return { [filter.nameDB]: { $lte: filterValue } };
    case "greater-than-equal":
      return { [filter.nameDB]: { $gte: filterValue } };
    default:
      return {};
  }
}

export function handleAutomatFilter(filter: CommonFilter) {
  switch (filter.additionalOptions?.settingField) {
    case "slugifator":
      return { [filter.nameDB]: { $eq: filter.value } };
    default:
      const value = filter.value === "true" ? true : false;
      return { [filter.nameDB]: { $eq: value } };
  }
}
