import { slugify } from "transliteration";

export default function customSlugify(value: string): string {
  const slug = slugify(value, {
    separator: "_",
    replace: [[/[^a-zA-Zа-яА-Я0-9\s]/g, ""]],
    trim: true,
  });

  return slug.replace(/_+/g, "_").replace(/^_|_$/g, "");
}
