/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import { errorCatch } from "../../func/common";
import { useSearchParams } from "react-router-dom";

const Pagination = ({
  page,
  setPage,
  typeModel,
  model,
  limitInPage,
  setLimitInPage,
  countAll,
  api,
  dontChangeUrl,
}) => {
  const [count, setCount] = useState(countAll ?? 10);
  const [localLimit, setLocalLimit] = useState(limitInPage ?? 40);
  let [searchParams, setSearchParams] = useSearchParams();

  const updateLocalLimit = useCallback(
    (value) => {
      if (isNaN(+value)) return;
      setLocalLimit(value === "" ? value : +value > 0 ? +value : 1);
    },
    [setLocalLimit],
  );

  useEffect(() => {
    setCount(countAll ?? 10);
  }, [countAll]);

  useEffect(() => {
    setLocalLimit(limitInPage ?? 40);
  }, [limitInPage]);

  useEffect(() => {
    if (!countAll && typeModel && model)
      api
        .getCountDatas({ typeModel, model })
        .then((result) => {
          setCount(result.count);
        })
        .catch((err) => {
          errorCatch(err);
        });
  }, [typeModel, model, countAll, api]);

  const changePage = (page) => {
    setPage(page);
    if (!dontChangeUrl) setSearchParams({ ...searchParams, page });
  };

  const pageHTML = [];

  if (page == 1) {
    pageHTML.push(
      <ul key={page} className="pagination m-0">
        <li className="page-item active">
          <a className="page-link" href="#">
            1
          </a>
        </li>
        {count / limitInPage > 1 ? (
          <li
            className="page-item "
            onClick={(e) => {
              e.preventDefault();
              changePage(2);
            }}
          >
            <a className="page-link" href="#">
              2
            </a>
          </li>
        ) : null}
        {count / limitInPage > 2 ? (
          <li className="page-item">
            <a
              className="page-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                changePage(3);
              }}
            >
              3
            </a>
          </li>
        ) : null}
        {count / limitInPage > 3 ? (
          <li
            className="page-item"
            onClick={(e) => {
              e.preventDefault();
              changePage(+page + 1);
            }}
          >
            <a className="page-link" href="#" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        ) : null}
      </ul>,
    );
  } else {
    pageHTML.push(
      <ul key={page} className="pagination m-0">
        <li
          className="page-item"
          onClick={(e) => {
            e.preventDefault();
            changePage(+page - 1);
          }}
        >
          <a className="page-link" href="#" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li
          className="page-item"
          onClick={(e) => {
            e.preventDefault();
            changePage(+page - 1);
          }}
        >
          <a className="page-link" href="#">
            {+page - 1}
          </a>
        </li>

        <li className="page-item active">
          <a className="page-link" href="#">
            {page}
          </a>
        </li>

        {count / limitInPage > page ? (
          <li
            className="page-item"
            onClick={(e) => {
              e.preventDefault();
              changePage(+page + 1);
            }}
          >
            <a className="page-link" href="#">
              {+page + 1}
            </a>
          </li>
        ) : null}
        {count / limitInPage > page + 1 ? (
          <li
            className="page-item"
            onClick={(e) => {
              e.preventDefault();
              changePage(+page + 1);
            }}
          >
            <a className="page-link" href="#" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        ) : null}
      </ul>,
    );
  }

  return (
    <div className="d-flex justify-content-between mb-4">
      <div>
        <nav aria-label="Page navigation example">{pageHTML}</nav>
      </div>
      <div className="input-group input-group_small">
        <span className="input-group-text">Выводить</span>
        <input
          type="text"
          className="form-control"
          placeholder="40"
          value={localLimit}
          onChange={({ target }) => {
            updateLocalLimit(target.value);
          }}
          onBlur={({ target }) => {
            if (!isNaN(+target.value)) setLimitInPage(target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              if (!isNaN(+e.target.value)) setLimitInPage(e.target.value);
            }
          }}
        />
      </div>
    </div>
  );
};

export default Pagination;
