/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import Cropper from "cropperjs";
import api from "func/api";
import RoundButton from "components/RoundButton/RoundButton";
import HddOutlineIcon from "icons/HddOutlineIcon";

const INIT_ZOOM_OUT = -0.05;

const cropInit = (elem) => {
  if (!elem) return;

  const crop = new Cropper(elem, {
    aspectRatio: NaN,
    preview: ".img-preview",
    autoCropArea: 0.95,
    crop: function (event) {},
    ready: function () {
      crop.zoom(INIT_ZOOM_OUT);
    },
    responsive: true,
  });
  crop.reset();
  // crop.zoom(-0.1);
  console.log(`crop`, crop);

  return crop;
};

const PopupEditMedia = (props) => {
  const { close, link, onSave } = props;
  const imgMain = useRef(null);

  const [crop, setCrop] = useState(null);

  useEffect(() => {
    if (!link && imgMain.current) return;

    const crop = cropInit(imgMain.current);
    setCrop(crop);

    return () => {
      if (crop) crop.destroy();
    };
  }, [link, imgMain]);

  return (
    <div className="modal-dialog modal-xl">
      <div
        className="modal-content"
        style={{
          padding: "3px",
          overflow: "hidden",
          minHeight: "450px",
        }}
      >
        <div className="row">
          <div className="col-8 modal-img">
            <div className="h-100 media-block">
              <img src={link} ref={imgMain} alt="crop image"></img>
            </div>
          </div>
          <div className="col-4">
            <div className="modal-tool">
              <div className="img-preview border"></div>
              <div>
                <div className="flexim-cropper-tools">
                  <button
                    className="flexim-cropper-tool-button"
                    onClick={() => {
                      if (crop) {
                        crop.zoom(0.1);
                      }
                    }}
                  >
                    <i className="bi bi-zoom-in"></i>Zoom in
                  </button>
                  <button
                    className="flexim-cropper-tool-button"
                    onClick={() => {
                      if (crop) {
                        crop.zoom(-0.1);
                      }
                    }}
                  >
                    <i className="bi bi-zoom-in"></i>Zoom out
                  </button>
                  <button
                    className="flexim-cropper-tool-button"
                    onClick={() => {
                      if (crop) {
                        crop.rotate(-45);
                      }
                    }}
                  >
                    <i className="bi bi-arrow-counterclockwise"></i>
                    Turn left
                  </button>
                  <button
                    className="flexim-cropper-tool-button"
                    onClick={() => {
                      if (crop) {
                        crop.rotate(45);
                      }
                    }}
                  >
                    <i className="bi bi-arrow-clockwise"></i>Turn right
                  </button>
                  <button
                    className="flexim-cropper-tool-button"
                    onClick={() => {
                      if (crop) {
                        crop.reset();
                        crop.zoom(INIT_ZOOM_OUT);
                      }
                    }}
                  >
                    <i className="bi bi-arrow-repeat"></i>Reset
                  </button>
                </div>
                <div
                  className="d-flex"
                  style={{
                    position: "absolute",
                    bottom: "16px",
                  }}
                >
                  <RoundButton
                    onClick={close}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "98px",
                      marginRight: "14px",
                      padding: "5px 19.5px",
                    }}
                  >
                    Cancel
                  </RoundButton>
                  <RoundButton
                    before={<HddOutlineIcon />}
                    appearance="inverted"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "98px",
                      marginRight: "14px",
                      padding: "5px 19.5px",
                    }}
                    // eslint-disable-next-line react/no-unknown-property
                    download="cropped.jpg"
                    onClick={(e) => {
                      if (crop) {
                        const data = crop
                          .getCroppedCanvas()
                          .toDataURL("image/jpeg");

                        api
                          .updMedia({ data, name: props.name, id: props.id })
                          .then((result) => {
                            onSave(result);
                            close();
                          })
                          .catch((err) => {
                            console.log(`err`, err);
                          });
                      }
                    }}
                  >
                    Save
                  </RoundButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupEditMedia;
