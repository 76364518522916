import { useMemo, useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

import { slugify } from "transliteration";

import { sanitizeNameDB } from "comp/pageConstructor/funcs/common";
import styles from "./StartupModal.module.scss";
import customSlugify from "utils/customSlugify";

// DISABLED BY CU 86bz18yhu
const IS_DISABLED_LOCK_CONTENT_TYPES = true;

// TODO: Need to refactor this component (too much callbacks-like props)
export default function StartupModal({
  chooseLockTypeNameBD,
  setChooseLockTypeNameBD,
  lockTypes,
  name,
  setName,
  setIsInitialModalData,
  isInitialModalData,
  typeCreate,
  setDatabaseName,
}) {
  const lockTypesArr = Object.values(lockTypes);

  const [slugValue, setSlugValue] = useState("");

  const [currentLockType, setCurrentLockType] = useState(null);

  useEffect(() => {
    if (isInitialModalData) {
      setName("");
      setSlugValue("");
      setIsInitialModalData(false);
      return;
    }

    const currentName = currentLockType?.name ?? name;
    setName(currentName);
    setSlugValue(slugify(slugValue));
  }, [
    isInitialModalData,
    currentLockType,
    name,
    setName,
    slugValue,
    setIsInitialModalData,
  ]);

  const shouldDisableInputFields = useMemo(() => {
    return currentLockType !== null;
  }, [currentLockType]);

  const showLockTypes = useMemo(
    () =>
      !IS_DISABLED_LOCK_CONTENT_TYPES &&
      typeCreate === "collection" &&
      lockTypesArr.length > 0,
    [typeCreate, lockTypesArr],
  );

  return (
    <div className="modal-body edit-field">
      <div className="row">
        <div className="col">
          <p className={styles.headline}>Displayed name</p>
          <div className="input-group mb-2">
            <input
              className="flexim-input"
              type="text"
              value={name}
              onChange={(e) => {
                const value = e.target.value;

                setName(value);

                const slugifiedValue = sanitizeNameDB(
                  currentLockType?.slug ?? customSlugify(value),
                );

                setSlugValue(slugifiedValue);
                setDatabaseName(slugifiedValue);
              }}
              disabled={shouldDisableInputFields}
            />
          </div>
          <p className={styles.headline}>Slug</p>
          <div className="input-group mb-2">
            <input
              className="flexim-input"
              type="text"
              value={slugValue}
              disabled
            />
          </div>
        </div>
        {showLockTypes && (
          <div className="col col-6">
            <p className={styles.headline}>Template</p>
            <div className="d-flex">
              <button
                className={`${styles["switch-button"]}${
                  chooseLockTypeNameBD
                    ? ""
                    : ` ${styles["switch-button--active"]}`
                }`}
                onClick={() => {
                  setCurrentLockType(null);
                  setName("");
                  setSlugValue("");
                  setChooseLockTypeNameBD(null);
                }}
              >
                No
              </button>

              {lockTypesArr.map((ltype) => (
                <button
                  key={ltype.nameDB}
                  className={`${styles["switch-button"]}${
                    chooseLockTypeNameBD === ltype.nameDB
                      ? ` ${styles["switch-button--active"]}`
                      : ""
                  }`}
                  onClick={() => {
                    setCurrentLockType(ltype);
                    setChooseLockTypeNameBD(ltype.nameDB);
                    setSlugValue(ltype.nameDB);
                  }}
                >
                  {ltype.name}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

StartupModal.propTypes = {
  chooseLockTypeNameBD: PropTypes.string,
  setChooseLockTypeNameBD: PropTypes.func,
  lockTypes: PropTypes.object,
  name: PropTypes.string,
  setName: PropTypes.func,
  isInitialModalData: PropTypes.bool,
  setIsInitialModalData: PropTypes.func,
  typeCreate: PropTypes.string,
  setDatabaseName: PropTypes.func,
};
