import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Input from "components/Input/Input";

import styles from "./Pagination.module.scss";

type PaginationProps = {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
};

type PaginatorElement = {
  type: "page" | "next" | "prev" | "decorative";
  content: number | string;
};

const maximumItemsPerPage = 200;

const Pagination: FC<PaginationProps> = ({
  totalItems,
  itemsPerPage,
  currentPage,
}) => {
  const [paginator, setPaginator] = useState<PaginatorElement[]>([]);
  const [isInputEmpty, setIsInputEmpty] = useState(false);
  const [inputValue, setInputValue] = useState(itemsPerPage.toString());
  const [searchParams, setSearchParams] = useSearchParams({
    page: currentPage.toString(),
    itemsPerPage: itemsPerPage.toString(),
  });

  const itemsPerPageFromURL = useMemo(() => {
    const itemsPerPageParam = searchParams.get("itemsPerPage");
    let itemsPerPageFromURL = parseInt(
      itemsPerPageParam || itemsPerPage.toString(),
      10,
    );

    if (itemsPerPageFromURL > maximumItemsPerPage) {
      itemsPerPageFromURL = maximumItemsPerPage;
    }

    return itemsPerPageFromURL;
  }, [itemsPerPage, searchParams]);

  const totalPages = useMemo(
    () => Math.ceil(totalItems / itemsPerPageFromURL),
    [totalItems, itemsPerPageFromURL],
  );

  const navigate = useNavigate();

  const addQueryParam = useCallback(
    (param: string, value: string, ...additionalParams: [string, string][]) => {
      const searchParams = new URLSearchParams(window.location.search);

      searchParams.set(param, value);

      if (additionalParams.length > 0) {
        additionalParams.forEach(([param, value]) => {
          searchParams.set(param, value);
        });
      }

      navigate(`${window.location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    },
    [navigate],
  );

  // Обновляем пагинатор при изменении текущей страницы
  useEffect(() => {
    const pageParam = searchParams.get("page");
    const itemsPerPageParam = searchParams.get("itemsPerPage") || "20";
    let pageFromURL = parseInt(pageParam || "1", 10);

    // Проверяем валидность страницы
    if (!Number.isInteger(pageFromURL) || pageFromURL <= 0) {
      pageFromURL = 1;
    }

    if (pageFromURL > totalPages && totalPages > 0) {
      return addQueryParam("page", totalPages.toString());
    }

    const newItemsPerPage =
      Number(itemsPerPageParam) < maximumItemsPerPage
        ? Number(itemsPerPageParam)
        : maximumItemsPerPage;

    const onChangeHandler = (itemsPerPage: string) => {
      setSearchParams((params) => {
        const searchParams = new URLSearchParams(params);
        searchParams.set("itemsPerPage", itemsPerPage.toString());
        return searchParams.toString();
      });
    };

    onChangeHandler(newItemsPerPage.toString());

    const updatePaginator = (currentPage: number) => {
      let pages: PaginatorElement[] = [];

      // Добавляем кнопку "предыдущая", если не на первой странице
      if (currentPage > 1) {
        pages.push({ type: "prev", content: "‹" });
      }

      if (totalPages <= 4) {
        // Если страниц 4 или меньше, показываем все страницы
        for (let i = 1; i <= totalPages; i++) {
          pages.push({ type: "page", content: i });
        }
      } else {
        if (currentPage === 1 || currentPage === 2) {
          // Если текущая страница 1 или 2
          for (let i = 1; i <= 3; i++) {
            pages.push({ type: "page", content: i });
          }
          pages.push({ type: "decorative", content: "..." });
          pages.push({ type: "page", content: totalPages });
        } else if (currentPage === 3) {
          // Если текущая страница 3
          pages.push({ type: "page", content: 1 });
          pages.push({ type: "page", content: 2 });
          pages.push({ type: "page", content: 3 });
          pages.push({ type: "page", content: 4 });
          pages.push({ type: "decorative", content: "..." });
          pages.push({ type: "page", content: totalPages });
        } else if (currentPage === totalPages - 2) {
          // Если текущая страница за 2 до конца
          pages.push({ type: "page", content: 1 });
          pages.push({ type: "decorative", content: "..." });
          pages.push({ type: "page", content: currentPage - 1 });
          pages.push({ type: "page", content: currentPage });
          pages.push({ type: "page", content: currentPage + 1 });
          pages.push({ type: "page", content: totalPages });
        } else if (
          currentPage === totalPages - 1 ||
          currentPage === totalPages
        ) {
          // Если текущая страница предпоследняя или последняя
          pages.push({ type: "page", content: 1 });
          pages.push({ type: "decorative", content: "..." });
          for (let i = totalPages - 2; i <= totalPages; i++) {
            pages.push({ type: "page", content: i });
          }
        } else {
          // Текущая страница где-то в середине
          pages.push({ type: "page", content: 1 });
          pages.push({ type: "decorative", content: "..." });
          pages.push({ type: "page", content: currentPage - 1 });
          pages.push({ type: "page", content: currentPage });
          pages.push({ type: "page", content: currentPage + 1 });
          pages.push({ type: "decorative", content: "..." });
          pages.push({ type: "page", content: totalPages });
        }
      }

      // Добавляем кнопку "следующая", если не на последней странице
      if (currentPage < totalPages) {
        pages.push({ type: "next", content: "›" });
      }

      setPaginator(pages);
    };

    updatePaginator(pageFromURL);
  }, [
    inputValue,
    currentPage,
    itemsPerPage,
    totalPages,
    searchParams,
    addQueryParam,
    setSearchParams,
  ]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Игнорируем события при вводе в поля
      if (
        event.target instanceof HTMLInputElement ||
        event.target instanceof HTMLTextAreaElement
      ) {
        return;
      }

      let newPageNumber = currentPage;

      switch (event.code) {
        case "ArrowLeft":
          newPageNumber = Math.max(1, currentPage - 1);
          break;
        case "ArrowRight":
          if (currentPage < totalPages) {
            newPageNumber = currentPage + 1;
          }
          break;
        default:
          return;
      }

      addQueryParam("page", newPageNumber.toString());
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [currentPage, totalPages, addQueryParam]);

  const goToPage = useCallback(
    (pageNumber: number | "...") => {
      if (pageNumber !== "...") {
        addQueryParam("page", pageNumber.toString());
      }
    },
    [addQueryParam],
  );

  return (
    <div className={`${styles.pagination}`}>
      <div>
        {paginator.map((page, index) => (
          <button
            key={index}
            className={`${styles["page-item"]}${
              currentPage === page.content
                ? ` ${styles["page-item_active"]}`
                : ""
            }${
              page.type === "prev" || page.type === "next"
                ? ` ${styles["page-regulator"]}`
                : ""
            }${
              page.type === "decorative"
                ? ` ${styles["page-item_decorative"]}`
                : ""
            }`}
            onClick={() => {
              if (page.type === "page") {
                goToPage(Number(page.content));
              } else if (page.type === "next") {
                goToPage(currentPage + 1);
              } else if (page.type === "prev") {
                goToPage(currentPage - 1);
              }
            }}
            disabled={page.type === "decorative"}
          >
            {page.content}
          </button>
        ))}
      </div>
      <div className={styles["per-page-controll-container"]}>
        <span className={styles.text}>Show</span>
        <div className={styles["input-container"]}>
          <Input
            value={isInputEmpty ? "" : itemsPerPage}
            type="number"
            min={1}
            onChange={(event) => {
              let value = event.target.value;

              if (value === "") {
                setIsInputEmpty(true);
                return;
              }

              setIsInputEmpty(false);

              if (Number(value) > 0 && !isNaN(Number(value))) {
                setInputValue(value);
                addQueryParam("itemsPerPage", value, ["page", "1"]);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
